<template>
  <div id="app">
    <section class="section position-relative popUpFather">
      <div class="mt-4 flex-wrap justify-content-between">
        <div class="p-0 col-12 col-md-12 mb-2">
          <div class="p-0 col-sm-auto row m-0">
            <div class="my-auto col-md-2 p-0">
              <h4 class="card-title my-auto">Liste des comités</h4>
            </div>

            <div class="p-0 col-md-10 d-flex flex justify-content-end">
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 200px !important"
              >
                <b-form-input
                  v-model="searchQuery.libelle"
                  type="text"
                  placeholder="Libellé"
                ></b-form-input>
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 200px !important"
              >
                <v-select
                  label="value"
                  v-model="searchQuery.responsable"
                  :options="responsables"
                  :reduce="(responsable) => responsable.id"
                  class="bg-white h-100"
                  placeholder="Responsable"
                />
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 18% !important"
              >
                <v-select
                  v-model="searchQuery.status"
                  :options="comiteStatus"
                  placeholder="Statut"
                  class="bg-white h-100"
                />
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 200px !important"
              >
                <v-select
                  :options="responsables"
                  :reduce="(responsable) => responsable.id"
                  label="value"
                  v-model="searchQuery.membre"
                  placeholder="Membre"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <div class="p-0 pl-2 my-auto md-col-3">
                <customAddButton
                  class="px-2"
                  text=" Nouveau comité de gouvernance"
                  style="width: 254px"
                  @click="$router.push({ name: 'add-committee' })"
                ></customAddButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-0 bg-secondary" />

      <CommitteeTable
        :items="filteredData"
        :fields="committees.tableFields"
        @showItem="$router.push({ name: 'show-committee' })"
        @editItem="$router.push({ name: 'edit-committee' })"
        @deleteItem="deleteItem"
        :loading="loadingData"
        @showMembers="showMembers"
      ></CommitteeTable>
    </section>

    <div v-if="showMembersList" to="modals-xyz-549">
      <ModalMembers @close="showMembersList = !showMembersList">
        <MembersList :items="selectedMembers" />
      </ModalMembers>
    </div>
  </div>
</template>

<script>
import ModalMembers from "@/components/custom/ModalMembers";
import CommitteeTable from "./components/CommitteeTable.vue";
import MembersList from "./components/MembersList.vue";
import { mapGetters } from "vuex";
import customAddButton from "../../../components/custom/CustomAddButton";
import Swal from "sweetalert2";

//import Swal from "sweetalert2";

export default {
  name: "Committee",
  components: {
    ModalMembers,
    customAddButton,
    CommitteeTable,
    MembersList,
  },
  methods: {
    show: function () {
      this.showMdl = !this.showMdl;
    },
    showMembers(members, name) {
      if (members.length > 0) {
        members = {
          user: members,
          name: name,
        };
        this.selectedMembers = members;
        this.showMembersList = true;
      }
    },
    showItem(item) {
      this.selectedItem = item;
      this.showVisionModal = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showEditVisionModal = true;
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("comite/deleteComite", id).then(() => {
            this.$store.dispatch("comite/fetchAllComites");
            Swal.fire("Supprimé!", "", "success");
          });
        }
      });
    },
  },
  data: function () {
    return {
      searchQuery: {
        libelle: "",
        responsable: "",
        status: "",
        membre: "",
      },
      loadingData: true,
      showMdl: false,
      showEditCommittee: false,
      showMembersList: false,
      showTransferModal: false,
      selectedMembers: [],
      selecteddata: [],

      committees: {
        tableItems: [
          {},

          /*{
            committee_label: "Comité 1",
            description: "Lorem ipsum dolum.",
            periodicity: "Lorem",
            responsable: {
              name: "S.E",
              full_name: "Steve Ewore",
              img: "https://source.unsplash.com/random/150x150",
            },
            members: 8,
            actions: ["show", "delete"],
          },*/
        ],
        tableFields: [
          { key: "name", label: "Comité", sortable: true },
          { key: "description", label: "Description", sortable: true },
          { key: "periodicite", label: "Périodicité", sortable: true },
          { key: "responsable", label: "Responsable", sortable: true },
          {
            key: "members",
            label: "Nb de membres",
            thStyle: { textAlign: "center !important" },
            sortable: true,
          },
          { key: "Statut", label: "Statut", sortable: true },
          {
            key: "actions",
            label: "Actions",
            thStyle: {
              width: "150px !important",
              minWidth: "150px !important",
            },
            sortable: false,
          },
        ],
      },
    };
  },

  created() {
    this.$store.dispatch("comite/fetchAllComites");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchComiteStatus");
  },

  computed: {
    ...mapGetters("comite", ["COMITES"]),
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["COMITE_STATUS"]),
    comiteStatus() {
      return this.COMITE_STATUS;
    },
    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));

      return data;
    },
    filteredData() {
      return this.selecteddata
        .filter(
          (comite) =>
            comite.name
              .toLowerCase()
              .indexOf(this.searchQuery.libelle.toLowerCase()) > -1 &&
            comite.status
              .toLowerCase()
              .indexOf(
                this.searchQuery.status
                  ? this.searchQuery.status.toLowerCase()
                  : ""
              ) > -1 &&
            (this.searchQuery.responsable
              ? comite.responsable.id === parseInt(this.searchQuery.responsable)
              : true)
        )
        .filter((comite) => {
          if (this.searchQuery.membre) {
            if (comite.members.length) {
              return comite.members.filter((member) =>
                member && this.searchQuery.membre
                  ? member.id === this.searchQuery.membre
                  : false
              ).length;
            } else return false;
          } else return true;
        });
    },
  },
  watch: {
    COMITES: function () {
      this.selecteddata = this.COMITES.map((comite) => ({
        ...comite,
        actions: ["show", "delete"],
      }));
      this.loadingData = false;
    },
  },
};
</script>
<style>
add {
  width: 100%;
}

@media (min-width: 600px) {
  .add {
    width: max-content;
  }
}

.top_gantt_controls {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 10px;
}

.header_sd_menu {
  height: 60px;
  background: #37d3bc !important;
  width: 100%;
  color: white;
  border: 0;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}

.fl_1 {
  flex: 1;
}

.fl_2 {
  flex: 2;
}

.fl_3 {
  flex: 3;
}

.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.cc-red g rect {
  fill: red !important;
}
</style>
<style>
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.board-wrapper .portlet-card-list {
  padding-left: 0;
  list-style: none;
  min-height: 70px;
  margin-bottom: 16px;
  overflow: hidden;
  max-height: 100vh;
  overflow-y: auto;
}
.drag-container {
  overflow-x: auto;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
/* #gantt-table thead tr th {
  padding-top: 23px;
  padding-bottom: 23px;
} */

/* #gantt-table tbody tr td {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 10px !important;
} */
#gantt-table tbody tr:nth-child(odd) {
  background: #ffffff;
}
#gantt-table tbody tr:nth-child(even) {
  background: #f5f5f5;
}

.gantt .grid-header {
  fill: #37d3bc !important;
  stroke: #e0e0e0;
  stroke-width: 1.4;
}
#gantt-table thead {
  background: #37d3bc !important;
}
.gantt .lower-text {
  fill: white;
}
.gantt .upper-text {
  fill: white;
}
.gantt .grid-row {
  fill: #ffffff;
  /* height: 40.66px !important; */
}

.gantt .grid-header {
  fill: #37d3bc !important;
  stroke: #e0e0e0;
  stroke-width: 1.4;
}
#gantt-table thead {
  background: #37d3bc !important;
}
.gantt .lower-text {
  fill: white;
}
.gantt .upper-text {
  fill: white;
}
.gantt .grid-row {
  fill: #ffffff;
}
</style>
