<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <template v-slot:cell(members)="data">
                <div class="text-center">
                  <b-badge
                    pill
                    @click="$emit('showMembers', data.value, data.item.name)"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                  >
                    {{ data.value.length }}
                  </b-badge>
                </div>
              </template>

              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-committee',
                    params: {
                      slug: data.item.slug,
                      id: data.item.id,
                    },
                  }"
                >
                  {{ data.value }}
                </b-link>
              </template>
              <template v-slot:cell(description)="data">
                <div>
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(periodicity)="data">
                <p>{{ data.value.periodicite }}</p>
              </template>
              <template v-slot:cell(Statut)="data">
                <span>{{ data.item.status }}</span>
                <!-- 
                             :style="`color:${
                    data.item.status == 'En cours'
                      ? '#4287f5'
                      : data.item.status == 'En attente'
                      ? '#ffc000'
                      : data.item.status == 'Rejeté'
                      ? 'red'
                      : data.item.status == 'Terminé'
                      ? '#0f7316'
                      : 'gray'
                  };`"
                 -->
              </template>

              <template v-slot:cell(responsable)="data">
                <TableResponsable :responsable="data.value" />
              </template>

              <template v-slot:cell(actions)="data">
                <div class="container ml-3 justify-content-end">
                  <!-- <b-button
                    v-if="data.value.includes('show')"
                    @click="
                      $router.push({
                        name: 'show-committee',
                        params: {
                          slug: data.item.slug,
                          id: data.item.id,
                        },
                      })
                    "
                    variant="light"
                    pill
                    size="sm"
                    style="width: 40px; height: 40px"
                    class="d-flex align-items-center justify-content-center"
                    ><i class="mdi mdi-eye"></i
                  ></b-button> -->
                  <b-button
                    v-if="data.value.includes('edit')"
                    @click="$emit('editItem', data.item)"
                    variant="light"
                    pill
                    size="sm"
                    style="width: 40px; height: 40px"
                    class="d-flex align-items-center justify-content-center"
                    ><i class="mdi mdi-pencil-outline"></i
                  ></b-button>
                  <b-button
                    v-if="data.value.includes('delete')"
                    @click="$emit('deleteItem', data.item.id)"
                    variant="light"
                    pill
                    size="sm"
                    style="width: 40px; height: 40px"
                    class="d-flex align-items-center justify-content-center"
                    ><i class="mdi mdi-delete"></i
                  ></b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";

//import Swal from "sweetalert2";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedItem: null,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
  },
};
</script>
1
<style>
.p-2 {
  padding: 0.5rem !important;
}
</style>
